import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import PRPreview from '../components/PRPreview/PRPreview'
import { StyledLink } from '../components/Buttons/Button'
import Layout from "../layouts/Layout"
import Seo from 'gatsby-plugin-wpgraphql-seo';

const PRList = ({
  data,
  pageContext: {nextPagePath, previousPagePath},
}) => {
  const posts = data.allWpPressrelease.nodes
  const [showFooter, setShowFooter] = useState(false)

  useEffect(() => {
    nextPagePath && setShowFooter(true)
    previousPagePath && setShowFooter(true)
  }, [nextPagePath, previousPagePath])


  return (
    <Layout title="Press Releases">
      <div className="container">
        <header className="mt-6 mb-12">
          <StyledLink noOutline arrow="left" to="/news-media/">BACK TO NEWS & MEDIA</StyledLink>
          <h1 className="mt-6">Press Releases</h1>
        </header>

        <ol>
          {posts.map(post => (
            <li key={post.id}>
             <PRPreview pr={post} />
            </li>
          ))}
        </ol>

          {showFooter && (
            <footer className="border-t-2 mt-12 flex justify-between">
              {previousPagePath && (
                <div>
                  <StyledLink to={previousPagePath} arrow="left" noOutline>Previous</StyledLink>
                </div>
              )}

              {nextPagePath && (
                <div>
                  <StyledLink to={nextPagePath} arrow="right" noOutline>Next</StyledLink>
                </div>
              )}
            </footer>
          )}
      </div>
    </Layout>
  )
}

export default PRList

export const prQuery = graphql`
  query PRPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPressrelease(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        id
        uri
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`
