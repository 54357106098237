import React from 'react'
import { Link } from 'gatsby'

import './PRPreview.scss'

const PRPreview = ({ pr }) => {
  const {
    title, uri, date
  } = pr
  return (
    <div className="mb-8">
      <h3 className="uppercase">{date}</h3>
      <p><Link to={uri}>{title}</Link></p>
    </div>
  )
}

export default PRPreview
